<template>
    <a-layout id='layout'>
        <a-layout-header :trigger="null" collapsible v-model="collapsed" class="slider"  breakpoint="md" :style="{position: 'fixed', zIndex: 1000, width: '100%' }">
            <div class="logo" @click="tohome">
                <img src="../../assets/logo.png" alt style="width:50px;height: 50px;">
                <p style="margin:10px;">NFXBUS</p>
            </div>
            <Sidemenu :selected-index="selectedIndex" :selected-keys="selectedKeys" :collapsed="collapsed">
            </Sidemenu>
        </a-layout-header>
        <a-layout-content :style="{ background: '#1B1F24', minHeight: '280px', flex: 'none',marginTop:'50px'}">
            <router-view v-if="isRouterAlive"></router-view>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>
<script>
import Footer from "@/components/page/footer";
import Sidemenu from "@/components/Menu/Menu";
export default {
    name: "Layout",
    components: {
        Footer,
        Sidemenu
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    created() {
        // this.checkToken();
    },
    data() {
        return {
            selectedIndex: "home",
            selectedKeys: "home",
            name: "",
            collapsed: false,
            isRouterAlive: true
        };
    },
    watch: {
        '$route.path': function () {
            this.selectedKeys = this.$route.path.replace("/plat/","")
        }
    },
    methods: {
        tohome() {
            this.$router.push({ path: "home" });
        },
        initData() {
            let info = this.$cookies.get("userInfo");
            if (info) {
                let { name } = JSON.parse(info);
                this.name = name;
            }
        },
        reload() {
            this.isRouterAlive = false
            this.$nextTick(() => (this.isRouterAlive = true))
        },
        async checkToken() {
            window.console.log("---------layout")
            let now = new Date().getTime();
            let deadline = this.$cookies.get("deadline");
            let token = this.$cookies.get("token");
            let userInfo = this.$cookies.get("userInfo");
            if (!deadline || !token || !userInfo) {
                return this.$router.push({ path: "login" });
            }
            if (deadline < now) {
                return this.$router.push({ path: "login" });
            }
            this.initData();
        }

    }
};
</script>
<style>
html,
body {
    width: 100%;
    height: 100%;
    font-size: 16px;
}

.ant-layout-footer {
    padding: 0 !important;
}

#layout {
    height: 100%;
    /*min-width: 1280px;*/
}

#layout .header {
    padding: 0 20px 0 0;
    background: #fff;
    /*box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);*/
}

#layout .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#layout .trigger:hover {
    color: #1890ff;
}

.slider {
    display: flex;
    justify-content: space-between;
    background-color: #161B22;
    /*box-shadow: 7px 0px 10px 0px rgb(67 217 142/30%);*/
}

#layout .logo {
    margin-left: 80px;
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
    font-family: Lao UI;
    font-weight: bold;
    color: #FFFFFF;
}

@media screen and (max-width: 768px){
    #layout .logo {
        margin-left: 0px;
        font-size: 1.25rem;
    }
}

#layout .logo .logo_icon img {
    height: 50px;
}

#layout .logo h1 {
    color: #fff;
    font-size: 20px;
    padding: 8px 0 0 24px;
}

.layout_menu_item {
    line-height: 60px !important;
    height: 60px !important;
}
</style>
