const kr = {
    aftersales:{
        "contactus":"연락처 빠른 응답",
        "workorder":"질문 목록",
        "createjob":"질문 만들기",
        "submittinworkorder":"작업 지시서를 제출할 때는 오류 스크린샷, 계정 정보 등 가능한 한 상세한 정보를 포함해주시기 바랍니다. 이러한 정보는 문제를 신속하게 처리하는 데 매우 중요합니다.",
        "workordercontent":"제출된 질문 목록",
        "workorderreply":"회신하다",
        "subscriptionaccount":"계정에 가입",
        "problemtype":"문제 유형",
        "tobeprocessed":"처리 예정",
        "reply":"회신",
        "processed":"해결,응답 보기",
        "revoked":"취소됨",
        "create":"만들기",
        "workorderno":"작업 주문 번호",
        "product":"제품",
        "status":"상태",
        "type":"유형",
        "ticket":"티켓",
        "createtime":"생성시간",
    },
    excode:{
        "redemptioncode":"교환",
        "pleaseredemptioncode":"교환코드를 입력해주세요",
        "exchange":"지금사용",
        "notused":"사용하지 않았다",
        "used":"이미 사용 된",
        "expired":"만료",
        "status":"지위",
        "exchangetime":"교환 날짜",
        "remark":"설명",
        "createtime":"생성시간",
    },
    footer:{
        "contactus":"문의하기",
        "navigation":"목차",
        "home":"홈페이지",
        "friends":"연맹",
        "subscribe":"구독하다",
        "aftersales":"고객센터",
        "help":"돕다",
        "termsofuse":"이용약관",
        "refundpolicy":"환불 정책",
        "privacypolicy":"개인 정보 정책",
        "paymentmethod":"결제 방법",
        "language":"언어",
    },
    friends:{
        "promotioninfo":"판촉 정보",
        "currentlevel":"현재 레벨",
        "content":"독점 초대링크 및 할인 코드를 사용하여 다른 사람과 공유하여 수수료를 받을 수 있습니다.수준이 높을수록 커미션 점유율이 높아집니다.",
        "rolemembers":"구성원의 역할",
        "promotionallinks":"초대링크",
        "discountcodes":"할인 코드",
        "copy":"복사",
        "rebateratio":"수수료율",
        "discountratio":"할인율",
        "lowestdiscountratio":"사용자 구매 할인",
        "walletsandpoints":"지갑 및 동결 금액",
        "applywithdrawal":"출금 신청 후 영업일 기준 3일 이내에 검토 후 입금해 드립니다.",
        "withdraw":"돈을 인출",
        "record":"출금기록",
        "integral":"완전한",
        "wallet":"지갑",
        "freezeamount":"동결",
        "pointsused":"유튜브 제품 3일, 기타 제품 1일 동결",
        "orderdetails":"주문 상세 정보",
        "orderamount":"주문 금액",
        "earnings":"이익",
        "withdrawalsrecord":"출금 기록",
        "accounts":"계정",
        "accounttype":"계정 유형",
        "withdrawalamount":"인출 금액",
        "cancel":"취소",
        "submit":"제출",
        "greaterthan":"금액은 0보다 커야 합니다!",
        "normal":"유효",
        "invalid":"유효하지 않은",
        "freeze":"동결",
        "extracted":"추출됨",

        "bankcard":"은행카드",
        "promotionlink":"초대링크",
        "discountcode":"할인 코드",
        "waitingforapproval":"승인 대기 중",
        "waitingforpayment":"결제 대기 중",
        "notpass":"통과 안 함",
        "paid":"지불",

        "cashwithdrawalid":"현금지급 ID",
        "accountsreceivable":"미수금",
        "amount":"금액(USD)",
        "status":"상태",
        "applicationtime":"신청 시간",
        "processingresults":"처리 결과",

        "orderid":"주문 ID",
        "useramount":"사용자 수",
        "duration":"기간",
        "promotiontype":"프로모션 유형",
        "createtime":"생성 시간",
        "eainings":"수익(USD)",

        "receivingaccount":"수금 계좌",
        "nowithdrawableamount":"최소 현금 인출 30USD",
        "copysuccessfully":"복제 성공",
        "copyfailed":"복제 실패",
        "activetime":"입금 시간",
    },
    home:{
        'join':'가입하다',
        "texttitle":"더 저렴한 가격으로 OTT 서비스를 즐기세요",
        "buynow":"구입",
        "soldout":"제품 매진",
        "shoptitle":"주문 후 현재 로그인 사서함의 메시지 보기 (메일 주소가 올바른지 확인하십시오.)",
        "delivery":"즉시 배송",
        "after":"결제 후 바로 상품을 받아보세요",
        "certificate":"SSL 인증서",
        "payments":"SSL 보안 인증서로 안전한 환경에서 결제가 이루어집니다.",
        "onlinesupport":"24/7 온라인 지원",
        "privatecustomer":"24/7 온라인 개인 고객 서비스",
        "subscriber":"같은 니즈를 가진 가입자",
        "subscriptions":"동일한 국가에서 공통 요구사항을 가진 파트너 매칭을 우선시합니다.",
        "moneyback":"환불 보증",
        "refund":"환불을 신청할 수 있는 기간은 1-3 일입니다.(YouTube 3일 기타 제품 24시간)",
        "pwdreset":"비밀번호 리셋가능",
        "pwdresetdesc":"비밀번호 변경되었다면? 구독 페이지에서 비밀번호 초기화하시면",
        "parkingspace":"위치",
        "gift":"선물",
        "screen":"위치 공유",
        "1screen":"프로필 1개(공유됨)",
        "maxnum":"위치(사적인)",
        "month":"구입 시간",
        "buytyp":"구매 유형",
        "subscription":"계정",
        "voucher":"교환 코드(갱신 시 구매)",
        "discountcode":"할인 코드",
        "use":"사용",
        "paymentmethod":"지불 방법",
        "pleasechoose":"선택하십시오",
        "billingdetails":"결제 세부 정보",
        "payaddress":"지불 주소 정보",
        "firstname":"이름",
        "lastname":"성",
        "emailaddress":"이메일 주소",
        "phone":"핸드폰",
        "country":"국가/지역",
        "state":"국가코드",
        "city":"마을 / 도시",
        "address":"거리 주소",
        "housenumber":"문 번호와 거리 이름",
        "suiteunit":"아파트, 스위트, 유닛 등(선택 사항)",
        "zipcode":"우편 번호",
        "submit":"제출",
        "creditcard":"신용 카드",
        "cardnumber":"카드 번호",
        "expirydate":"만료 날짜",
        "nameoncard":"카드 소지자 이름",
        "savethiscard":"향후 결제를 위해 이 카드를 저장하세요.",
        "play":"지불하다",
        "emailerror":"이메일 오류",
        "payresluttitle":"결제 성공",

        "pleaseenter":"입력하십시오",
        "selectamonth":"월을 선택하세요.",
        "selectthenumber":"하위 계정 수를 선택하세요.",
        "selectthepurchase":"구매유형을 선택해주세요",
        "selectthepayment":"결제수단을 선택해주세요",
        "discountcodenotempty":"할인 코드는 비워 둘 수 없습니다!",
        "detail1":"<p>✅ 안정적이고 갱신 가능한 구독</p>\n" +
            "<p>✅ 4K+HDR 고화질, 독립 시청 기록</p>\n" +
            "<p>✅ 실시간 배송</p>\n" +
            "<p>✅ 모바일 단말기, PC, TV, TV 박스 지원</p>\n"+
            "<p>✅ 애프터 서비스 지원, 걱정 없는 환불</p>",
        "detail2":"<p>✅ 안정적인 구독, 12개월 요금 선불</p>\n" +
            "<p>✅ 공식 공유 ​​구독</p>\n" +
            "<p>✅ 실시간 배송</p>\n" +
            "<p>✅ 애프터 서비스 지원, 걱정 없는 환불</p>",
        "detail3":"<p>✅ 안정적인 구독, 12개월 요금 선불</p>\n" +
            "<p>✅ 콘텐츠 등급: 18+/R18(21)/TV-MA</p>\n" +
            "<p>✅ 실시간 배송</p>\n" +
            "<p>✅ 다양한 장치 지원: 휴대폰, 컴퓨터, TV</p>\n" +
            "<p>✅ 애프터 서비스 지원, 걱정 없는 환불</p>",
        "detail5":"<p>✅ 개인 YouTube 구독을 사용하여 프리미엄 가족 그룹에 가입하세요</p>\n" +
            "<p>✅ 초대 링크 보내기</p>\n" +
            "<p>✅ 목록과 구독을 유지하세요</p>\n" +
            "<p>✅ 애프터 서비스 지원, 걱정 없는 환불</p>\n"+
            "<p>✅ 프리미엄은 1명만 지원하며, 친척이나 친구를 초대할 수 없습니다</p>\n",
            // "<p>✅ 도움말: 구매하기 전에 play.google.com에서 계정 국가가 다음인지 확인하세요. ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG , CZ, SE, PT, BE, IL.</p>",
        "detail7":
            "<p>✅ 6명이 구독 1개 공유</p>\n" +
            "<p>✅ 공식 4.0 모델</p>\n" +
            "<p>✅ GPT 스토어 즐기기</p>\n" +
            "<p>✅ 플러그인 사용 가능</p>\n" +
            "<p>✅ API 금지</p>\n" +
            "<p>✅ 24시간 이내 걱정 없이 환불 가능</p>",

        "detail8":"<p>✅ 4명이 구독 1개를 공유합니다.</p>\n" +
            "<p>✅ 실시간 배송</p>\n" +
            "<p>✅ 모바일 단말기, PC, TV, TV 박스 지원</p>\n" +
            "<p>✅ 애프터 서비스 지원, 걱정 없는 환불</p>",
        "detail10":"<p>✅ 프리미엄 플랜, $3.88/월/스크린</p>\n" +
            "<p>✅ 실시간으로 초대 링크 보내기</p>\n" +
            "<p>✅ 비공개 구독, 모두 유지</p>\n" +
            "<p>✅ 즐겨찾기 및 목록</p>\n" +
            "<p>✅ 보증, 환불 보증</p>",

        "title":"상품정보를 확인해 주세요",
        "price":"가격",
        "product":"상품명",
        "note":"메모",
        "notecontent":"당사의 모든 구독은 서브 계정으로 공유 및 판매되므로 구매 전 이 점을 반드시 숙지하시기 바랍니다.",
        "notegpt":"6명이 계정을 공유합니다. 공식적인 제한은 3시간에 40문항입니다. 질문의 한계에 도달한 경우 잠시 기다렸다가 다시 질문하시기 바랍니다. NFXBUS는 공식 GPT Plus를 제공합니다. GPT 생성 속도는 인터넷 속도와 관련이 있습니다.",
        "notenetflix":"Netflix 고급 멤버십을 구입한 경우 “구독” 페이지에서 계정, 비밀번호를 확인하고 적절한 위치를 사용하라는 프롬프트를 누릅니다.자신의 위치에 들어간 후 pin을 설정하여 데이터를 잠그고 다른 사람이 사용하지 않도록 하십시오.\n" +
            "비밀번호가 변경되면 NFXBUS 구독 페이지에 로그인하여 새 계정 비밀번호를 확인하십시오.",
        "notespotify":"Spotify 고급 멤버십을 구입한 경우 “구독” 페이지에서 계정, 비밀번호를 확인하십시오.",
        "notedisney":"Disney+ 고급 멤버십을 구입한 경우 “구독” 페이지에서 계정, 비밀번호를 확인하고 적절한 위치를 사용하라는 프롬프트를 누릅니다.자신의 위치에 들어간 후 pin을 설정하여 데이터를 잠그고 다른 사람이 사용하지 않도록 하십시오.\n" +
            "비밀번호가 변경되면 NFXBUS 구독 페이지에 로그인하여 새 계정 비밀번호를 확인하십시오.",
        "noteyoutube":"YouTube Premium을 구입한 경우 “구독” 페이지에서 YouTube 로그인 사서함을 제출하십시오.제출 후 직원은 이메일로 YouTube Premium 홈 그룹 초대 링크를 보냅니다.메일박스 정보를 확인하고 가입을 수락하려면 클릭하십시오.",
    },
    login:{
        "welcomelogin":"로그인 환영합니다",
        "login":"로그인",
        "updatepwd":"비밀번호 변경",
        "reg":"이메일 회원가입",
        "agreement":", Terms of User 에 동의하고 Privacy Policy 을(를) 읽었음을 확인했음을 의미합니다.",
        "quicklogin":"신속한 로그인",
        "sendcode":"코드 보내기",
        "register":"등록하다",
        "changepassword":"비밀번호 변경",
        "verificationcode":"인증 코드",
        "name":"이름",
        "password":"비밀번호",
        "agapassword":"비밀번호를 다시 입력하세요",
        "modify":"수정하다",
        "validatepswlen":"비밀번호는 대문자와 소문자, 숫자, 특수문자 중 3가지 조합을 모두 충족하며, 길이는 8자에서 16자 사이!",
        "checkagreement":"계약 보기",
        "passwordinconsistency":"두 번 입력한 비밀번호가 일치하지 않습니다!",
        "passwordagain":"암호를 다시 입력하십시오.",
        "verificationsend":"인증 코드가 전송되었습니다. 확인하십시오.",
    },
    luckdraw:{
        "lotteryticket":"복권",
        "integral":"적분",
        "welcome":"nfxbus에 오신 것을 환영합니다. 이것은 우리의 활동 페이지입니다. 카드를 클릭하면 한 번의 클릭으로 경품을 받을 수 있습니다.\n" +
            "                                    보상에는 다양한 무료 계정 패키지가 포함되며,\n" +
            "                                    할인 코드 및 기타 할인, 귀하의 지원과 신뢰에 감사드립니다.",
        "none":"없음",
        "redemptioncode":"상환 코드",
        "copy":"복사",
        "prizelist":"상품 목록",
        "notused":"사용하지 않았다",
        "used":"이미 사용 된",
        "expired":"만료",

        "prizeno":"상품번호",
        "contents":"내용물",
        "exchangedate":"교환 날짜",
        "createtime":"생성시간",
        "exchange":"환전",
        "insufficientpoints":"불충분한 포인트!",
        "consumepoints":"10포인트를 소모하시겠습니까?",
        "tips":"팁",
        "comfirm":"확인",
        "cancel":"취소",
        "failtowin":"미당첨",
        "congratulations":"취득을 축하합니다",
        "copysucceeded":"복사 성공!",
        "copyfailed":"복사 실패!",
    },
    subscribe:{
        "renewnow":"계속 구매",
        "subscription":"변경하지 않고 동일한 구독을 갱신하세요",
        "independent":"4K+HDR HD, 독립 시청 기록",
        "guarantee":"환불 보증",
        "timeleft":"남은 시간",
        "openingtime":"계좌 개설 시간",
        "endtime":"종료 시간",
        "account":"계정",
        "password":"비밀번호",
        "profile":"소개",
        "pleaseuse":"사용 해주세요",
        "subaccount":"서브 계정",
        "myprofile":"다른 사람의 자리를 차지하지 마세요!",
        "renew":"갱신",
        "aftersales":"애프터 서비스",

        "yourownsub":"1.일련번호에 따라 본인 서브계정을 이용해주세요.",
        "delete":"2.다른 하위 계정을 수정/삭제하지 마십시오.",
        "modifysubscription":"3.구독 관련 정보 수정 금지",
        "reminder":"알림 ：어떤 문제가 발생할 경우 즉시 고객 서비스에 연락하여 NFXBUS 에서 서브스크립션을 구매하여 VIP 고객 서비스를 즐기실 수 있으며, 리필 시 번호 변경이\n" +
            "                                        필요 없습니다.",

        "youtubeloginemail":"YouTube 로그인 이메일을 입력하세요. 로그인 이메일 주소를 사용하여 가족 그룹에 가입해야 합니다.\n" +
            "                                                그렇지 않으면 가족에서 쫓겨날 것입니다. 도움이 필요하시면 이메일을 통해 Nfxbus 고객 서비스에 문의하십시오.",
        "inspectaddress":"계정 국가/지역을 확인하세요:KR, ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG, CZ, SE,PT, BE , 일리노이. 문제가 발생하면 시간 내에 티켓을 통해 직원에게 문의하십시오.",
        "aftersalesservice":"애프터 서비스",
        "support":"지원 페이지.",
        "submit":"제출하다",

        "buyit":"아직 구독이 안 되었으니 서둘러 구입해 주세요",
        "paymentsucceeded":"결제 성공",
        "month":"월을 선택하세요.",
        "number":"하위 계정 수를 선택하세요.",
        "purchase":"구매유형을 선택해주세요",
        "payment":"결제수단을 선택해주세요",
        "name":"카드 소유자 이름을 입력하십시오",
        "card":"카드번호를 입력해주세요",
        "cvv":"CVV를 입력하세요.",
        "expirydate":"만료 날짜를 입력하세요.",
        "processing":"처리 상태가 제출되었습니다. 작업 주문서에서 처리 상태를 확인하십시오.",
        "cannotempty":"Discount code cannot be empty!",
        "remind":"메시지를 보낼지 여부",
        "originalpassword":"이 작업의 원래 비밀번호는 더 이상 사용되지 않습니다. 계속하시겠습니까?",
        "passwordmeets":"비밀번호는 대문자, 소문자, 숫자, 특수문자 중 3가지 조합이 가능하며, 길이는 8자에서 16자 사이입니다!",
        "pwdupdatetime":"비밀번호 변경",
        "pwdupdatetips":"제출되었습니다. 잠시만 기다려 주세요.",

        "getverlink":"로그인 인증 링크 가져오기",
        "ignorefeature":"인증 코드를 입력하라는 메시지가 표시되지 않은 경우 이 기능을 무시하세요.",
        "openlink":"링크 열기",
        "clicklink":"링크를 받으려면 클릭하세요.",
    },
    userinfo:{
        "signout":"로그아웃",
        "nickname":"닉네임",
        "email":"이메일 주소",
        "code":"인증번호",
        "vercode":"인증번호",
        "avatar":"두상",
        "uploadavatar":"아바타를 업로드하세요",
        "logout":"로그아웃하시겠습니까?",
        "onlyjpgpng":"업로드된 사진은 JPG | png 형식만 가능합니다.",
        "cannotexceed":"업로드된 이미지의 크기는 5MB를 초과할 수 없습니다!"
    },
    wish:{
        "wish":"상품 목록",
        "help":"NFXBUS 는 도움이 되고자 하는 소망을 선택합니다.",
        "christmas":"크리스마스가 오면 이루어진다 소원을 담아",
        "submit":"제출하다",
        "mywish":"내 소원"
    },
    map:{
        "home":"홈페이지",
        "userinfo":"개인적인 정보",
        "login":"로그인",
        "withfriends":"연맹",
        "subscribe":"구독하다",
        "aftersales":"고객센터",
        "luckdraw":"행운추첨",
        "wish":"희망",
        "helpcenter":"도움말 센터",
        "tradingstamps":"환전",
    },
}

export default kr
