import Vue from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import router from '@/router/index';
import Print from 'vue-print-nb'
// import VueI18n from 'vue-i18n'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
// import enLocale from 'element-ui/lib/locale/lang/en'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import { axiosinstaller } from '@/request/request';
import ElFormRenderer from '@femessage/el-form-renderer'
import ElDataTable from '@femessage/el-data-table'
import config from './util/config'
import {MessageBox} from 'element-ui'
import './styles/element-variables.scss'
import VueClipBoard from 'vue-clipboard2'
import FBSignInButton from 'vue-facebook-signin-button'
import Cookies from "js-cookie";
// import vGoogleTranslate from "v-google-translate";
import i18n from '../public/languages/i18n'
import store from './store';
// Vue.use(vGoogleTranslate);
import { Crisp } from "crisp-sdk-web";

Crisp.configure("11105ade-ff9f-4392-bb67-c002cd1455a3");

// Crisp will be displayed
// Vue.use(VueI18n)
// Vue.config.lang = 'en'
// Vue.locale('zh-cn', zhLocale)
// Vue.locale('en', enLocale)
Vue.prototype.$cookies = Cookies;
Vue.use(FBSignInButton)
Vue.use(VueClipBoard)
Vue.prototype.$confirmel = MessageBox.confirm;
Vue.use(config);
Vue.use(axiosinstaller);
Vue.prototype.$axios = axios;
Vue.prototype.$store = store;
Vue.use(Element, { locale })
Vue.use(Antd);
Vue.use(Print);
Vue.component('el-form-renderer', ElFormRenderer)
Vue.component('el-data-table', ElDataTable)
import echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
axios.defaults.baseURL = '/api'

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  // el: '#app',
  created() {
  },
  methods: {
  }
}).$mount('#app')
