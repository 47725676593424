<template>
  <div>
    <a-menu class="pc"
            :defaultSelectedKeys="[selectedIndex]"
            v-model="selectedMenu"
            theme="dark"
            mode="horizontal"
            @click="handleClick"
            :style="{ lineHeight: '30px', marginTop:'17px'}"
    >
      <a-menu-item v-for="menuItem of menuListWithAuth" :key="menuItem.path" >
        <span class="menu__item">{{menuItem.title}}</span>
      </a-menu-item>
    </a-menu>

    <a-dropdown class="phone">
      <a class="ant-dropdown-link" @click="e => e.preventDefault()">
        <a-avatar src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/user.jpg" style="width: 100%;height: 100%"/>
      </a>
      <a-menu slot="overlay"
              :defaultSelectedKeys="[selectedIndex]"
              v-model="selectedMenu"
              theme="light"
              mode="vertical"
              @click="handleClick"
              :style="{ lineHeight: '64px' }"
      >
        <a-menu-item v-for="menuItem of menuListWithAuth" :key="menuItem.path">
          <img :src="'https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/navigation/'+menuItem.type" style="height: 20px;width: 20px;">
          <span class="menu__item">{{menuItem.title}}</span>
        </a-menu-item>
        <el-button @click="lognout" type="text" v-if="this.$store.state.map.loginstatus">
            <span class="menu__item">{{$t('userinfo.signout')}}</span>
        </el-button>
      </a-menu>
    </a-dropdown>
  </div>

</template>

<script>
  import { logoutToken } from "@/request/requests";
  // import {getUserInfo} from "@/util/util"
  import store from '@/store/index';
export default {
    inject: ['reload'],
    name:'Sidemenu',
    props:{
        menuIndex: {
            type: Number,
            default: 0
        },
        selectedIndex :{
            type: String,
            default: 'home'
        },
        selectedKeys:{
          type: String,
          default: 'home'
        },
        collapsed: {
          type: Boolean,
          default: true
        }
    },
    watch: {
      selectedKeys(value) {
        if(value) {
          this.selectedMenu = [value];
        }
      },
    },
    data() {
        return {
            login:false,
            selectedMenu:["home"]
            // menu:[]
        }
    },
    methods: {
      lognout() {
        this.$confirmel(this.$t('userinfo.logout'), this.$t('luckdraw.tips'), {
          confirmButtonText: this.$t('luckdraw.comfirm'),
          cancelButtonText: this.$t('luckdraw.cancel'),
          type: 'warning'
        }).then(() => {
          this.logout()
        }).catch(() => {
        });
      },
      async logout() {
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        await logoutToken();
        sessionStorage.removeItem("token");
        this.$router.push({ path: "login" })
      },
      handleClick({ key}) {
        this.reload();
        this.$router.push(key);
      },
      updateSelectedMenu(){
        this.selectedMenu=["login"]
      }
    },
    computed: {
      menuListWithAuth() {
        if(!store.state.map.menuList) return [];
        if (!this.login){
          this.updateSelectedMenu()
        }
        return store.state.map.menuList.filter( item => item.auth)
      }
    },
    mounted(){
      // this.$router.push('home');
      // let userInfo=getUserInfo()
      // if (userInfo){
      //   this.login=true
      // }
    },
    created() {
      // let userInfo=getUserInfo()
      // if (userInfo){
      //   this.login=true
      // }
      this.selectedMenu=[store.state.map.currentRoute.replace("/plat/","")]
    }
};
</script>

<style lang="less" scoped>
    .menu__item{
      margin-left: 10px;
      font-size: 16px;
    }
    /*.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {*/
    /*  background-color: #1A1A1A;*/
    /*}*/
    /*.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {*/
    /*  background-image: linear-gradient(to right ,#3AAF75 ,#203C2E)*/
    /*}*/
    /*.ant-menu-item-selected{*/
    /*  background-image: linear-gradient(to right ,#3AAF75 ,#203C2E)*/
    /*}*/
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu{
        border-radius: 30px;
    }
    .pc{
      display: block;
    }
    .phone{
      display: none;
    }
    @media screen and (max-width: 768px){
      .menu__item{
        font-size: 14px;
      }
      .pc{
        display: none;
      }
      .phone{
        display: block;
      }
    }
</style>
