
const state = {
  menuList: [
    {
      // title:'Home',
      title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      //   title: "With friends",
      title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      //   title: "Subscribe",
      title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      //   title: "Luckdraw",
      title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
  //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      //   title: "Redeem Code",
      title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      //   title: "After Sales",
      title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      // title:'Login',
      title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListNoLogin: [
    {
      // title:'Home',
      title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      //   title: "With friends",
      title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      //   title: "Subscribe",
      title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      //   title: "Luckdraw",
      title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      //   title: "Redeem Code",
      title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      //   title: "After Sales",
      title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      // title:'Login',
      title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLogin: [
    {
      // title:'Home',
      title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      //   title: "With friends",
      title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      //   title: "Subscribe",
      title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      //   title: "Luckdraw",
      title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      //   title: "Redeem Code",
      title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      //   title: "After Sales",
      title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      // title:'User info',
      title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],



  menuListNoLoginEn: [
    {
      title:'Home',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
        title: "With friends",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
        title: "Subscribe",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
        title: "Luckdraw",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
        title: "Redeem Code",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "After Sales",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'Login',
      // title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLoginEn: [
    {
      title:'Home',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
        title: "With friends",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
        title: "Subscribe",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
        title: "Luckdraw",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
        title: "Redeem Code",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "After Sales",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'User info',
      // title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],

  currentRoute:'/plat/home',
  loginstatus:false
};

const getters = {
  getMenuListData: (state) => state.menuList,
};

// mutations主要用来操作state
const mutations = {
  setMenuListData(state, data) {
    state.menuList = data;
  },
  setCurrentRoute(state, data) {
    state.currentRoute = data;
  },
  setLoginStatus(state, data) {
    state.loginstatus = data;
  },
};

export default {
  state,
  getters,
  mutations,
};
